import axios from "@/plugins/axios";
import {AxiosResponse} from "axios";

const resource = 'api/v1/alert';

export default {
    getAlertById(idAlert: number): Promise<AxiosResponse<IServerResponse<IAlert>>> {
        return axios.get(`${resource}/${idAlert}`);
    },

    getAlertEvents(idAlert): Promise<AxiosResponse<IServerResponse<IAlertEvent[]>>> {
        return axios.get(`${resource}/${idAlert}/events`);
    },

    addAlert(alert): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}`,alert);
    },

    updateAlert(idAlert, alert): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.put(`${resource}/${idAlert}`,alert);
    },

    deleteAlert(idAlert): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.delete(`${resource}/${idAlert}`);
    },

    subscribeUserToAlert(idAlert, user_alert_preference): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}/${idAlert}/subscription`,user_alert_preference);
    },

    updateSubscriptionAlert(idAlert, user_alert_preference): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.put(`${resource}/${idAlert}/subscription`,user_alert_preference);
    },

    acquittedAlert(idAlert): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.put(`${resource}/${idAlert}/acquit`);
    },

    unAcquittedAlert(idAlert): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.put(`${resource}/${idAlert}/disacquit`);
    }
}
<template>
    <div class="icon-picker">
        <v-combobox v-model="local_icon" :label="label" append-icon=""
            filled hide-details readonly @click="dialog_select_icon = true;"
        >
            <template v-slot:selection>
                <v-icon :color="color_icon" class="ml-1">
                    mdi-{{ local_icon }}
                </v-icon>
            </template>
        </v-combobox>

        <v-dialog v-model="dialog_select_icon" max-width="450px" width="80%">
            <v-card>
                <v-card-text class="pb-0 pt-6">
                    <v-text-field v-model="search_icon" :loading="searching" @input="search"
                        filled :placeholder="$t('icon_picker_component.search_icon')" prepend-inner-icon="mdi-magnify"
                    />

                    <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="200">
                        <v-col v-if="filter_icon.length !== 0" cols="12">
                            <span v-for="(item, i) in filter_icon" :key="i">
                                <v-btn v-if="i < nb_icon_show" @click="prepareEditIcon(item)"
                                       class="mr-2 mb-2" color="white" depressed fab small
                                >
                                    <v-icon> mdi-{{ item }}</v-icon>
                                </v-btn>
                            </span>
                        </v-col>
                        <div v-else class="mb-2">
                            <span>{{ $t('icon_picker_component.no_icon_found') }}</span>
                        </div>
                    </v-sheet>

                    <div class="text-center">
                        <v-btn class="font-12" color="primary" text @click="nb_icon_show += 50">
                            {{ $t('icon_picker_component.see_more') }}
                        </v-btn>
                    </div>
                </v-card-text>

                <v-card-actions class="justify-center">
                    <v-btn
                        text
                        @click="dialog_select_icon = false"
                    >
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>
                    <v-btn
                        color="secondary"
                        text
                        @click="editIcon()"
                        class="btn-secondary-loader"
                        :loading="loading"
                    >
                        <span class="font-weight-bold secondary--text">{{ $t('global.validate') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import list_icon from "../../../public/list_icon.json";

export default {
    name: 'IconPicker',
    props: {
        icon: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        color_icon: {
            type: String,
            required: false
        },
    },
    created() {
        this.filter_icon = this.list_icon;
    },
    data() {
        return {
            list_icon: list_icon,
            search_icon: '',
            local_icon: '',
            selected_icon: '',
            nb_icon_show: 50,
            filter_icon: [],
            dialog_select_icon: false,
            searching: false,
            loading: false
        }
    },
    watch: {
        icon: {
            handler() {
                this.local_icon = JSON.parse(JSON.stringify(this.icon));
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        search() {
            this.searching = true;
            this.filter_icon = [];
            if (this.search_icon === '') {
                this.filter_icon = this.list_icon;
            } else {
                this.list_icon.forEach(icon => {
                    if (icon.includes(this.search_icon)) {
                        this.filter_icon.push(icon);
                    }
                })
            }
            this.searching = false;
        },

        prepareEditIcon(item) {
            this.selected_icon = item;
        },

        editIcon() {
            this.loading = true;
            this.$emit('editIcon', this.selected_icon, this.type);
            this.dialog_select_icon = false;
            this.loading = false;
        }
    },
}
</script>
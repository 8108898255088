<template>
    <div id="dialog-update-alert-component">
        <v-dialog v-model="dialog_edit_alert" max-width="500px">
            <v-card>
                <v-card-title>
                    <p class="text-uppercase font-weight-bold mb-1 line-height-24">
                        {{ $t('dialog_update_alert_component.configure_alert') }}
                    </p>
                </v-card-title>

                <v-card-text class="pb-0">
                    <!-- Rebuild v-for to fix bug -->
                    <v-form ref="form_edit_alert" v-if="!rebuild">
                        <div v-for="(configuration, index) in local_alert.configuration" :key="index">
                            <p v-if="index > 0" class="text-uppercase font-weight-bold my-4 text-center font-16">
                                {{ $t('dialog_update_alert_component.and') }}
                            </p>

                            <v-card>
                                <v-card-title class="text-uppercase justify-space-between pointer py-1">
                                    <span class="font-14 font-weight-bold w-80"
                                          @click="manageDisplayAlertModule('configuration_' + index)"
                                    >
                                        {{ $t('dialog_update_alert_component.rule') }}
                                        {{ local_alert.configuration.length > 1 ? index + 1 : '' }}</span>
                                    <v-icon v-if="local_alert.configuration.length > 1" @click="deleteCondition(index)">
                                        mdi-close
                                    </v-icon>
                                </v-card-title>

                                <v-card-text v-if="display_alert_module['configuration_' + index]">
                                    <v-select v-model="local_alert.configuration[index].id_metric"
                                              :items="metrics"
                                              :menu-props="{ bottom: true, offsetY: true }"
                                              class="mb-4" filled hide-details
                                              item-text="label" item-value="id_metric"
                                              :label="$t('dialog_update_alert_component.measure_to_monitor')"
                                              @change="changeMetric(index)"
                                    />

                                    <v-select v-model="local_alert.configuration[index].type"
                                              :items="type_measure"
                                              :menu-props="{ bottom: true, offsetY: true }"
                                              filled item-text="text" item-value="value"
                                              :label="$t('dialog_update_alert_component.type')"
                                    />

                                    <v-expand-transition>
                                        <div v-if="local_alert.configuration[index].type === 'limit'">
                                            <p class="mb-0 font-weight-bold">
                                                {{ $t('dialog_update_alert_component.define_alert_condition') }}
                                            </p>

                                            <v-radio-group v-model="local_alert.configuration[index].operator"
                                                           class="mt-1">
                                                <v-radio value=">">
                                                    <template v-slot:label>
                                                        <span class="font-14">
                                                            {{ $t('dialog_update_alert_component.superior') }}
                                                        </span>
                                                    </template>
                                                </v-radio>

                                                <v-radio value="<">
                                                    <template v-slot:label>
                                                        <span class="font-14">
                                                            {{ $t('dialog_update_alert_component.inferior') }}
                                                        </span>
                                                    </template>
                                                </v-radio>

                                                <v-radio value="=">
                                                    <template v-slot:label>
                                                        <span class="font-14">
                                                            {{ $t('dialog_update_alert_component.equal') }}
                                                        </span>
                                                    </template>
                                                </v-radio>
                                            </v-radio-group>

                                            <v-row class="ma-0">
                                                <v-text-field class="text-field-duo" filled type="number"
                                                              :label="$t('dialog_update_alert_component.value')"
                                                              v-model="local_alert.configuration[index].limit"
                                                />
                                                <v-spacer/>
                                                <v-select v-model="local_alert.configuration[index].unit"
                                                          :items="level_unites[index]"
                                                          :menu-props="{ bottom: true, offsetY: true }"
                                                          class="text-field-duo" filled
                                                          item-value="id" item-text="value"
                                                          :label="$t('dialog_update_alert_component.unity')"
                                                >
                                                </v-select>
                                            </v-row>
                                        </div>

                                        <div v-else-if="local_alert.configuration[index].type === 'variation'">
                                            <p class="mb-0 font-weight-bold">
                                                {{ $t('dialog_update_alert_component.define_alert_condition') }}
                                            </p>

                                            <v-radio-group v-model="local_alert.configuration[index].operator"
                                                           class="mt-1">
                                                <v-radio value="-">
                                                    <template v-slot:label>
                                                        <span class="font-14">
                                                            {{ $t('dialog_update_alert_component.diminution') }}
                                                        </span>
                                                    </template>
                                                </v-radio>

                                                <v-radio value="+">
                                                    <template v-slot:label>
                                                        <span class="font-14">
                                                            {{ $t('dialog_update_alert_component.augmentation') }}
                                                        </span>
                                                    </template>
                                                </v-radio>
                                            </v-radio-group>

                                            <v-row class="ma-0">
                                                <v-text-field v-model="local_alert.configuration[index].limit"
                                                              class="text-field-duo" filled hide-details
                                                              :label="$t('dialog_update_alert_component.value')"
                                                              type="number"
                                                />
                                                <v-spacer/>
                                                <v-select v-model="local_alert.configuration[index].unit"
                                                          :items="level_unites[index]"
                                                          :menu-props="{ bottom: true, offsetY: true }"
                                                          class="text-field-duo" filled hide-details
                                                          item-value="id" item-text="value"
                                                          :label="$t('dialog_update_alert_component.unity')"
                                                />
                                            </v-row>

                                            <p class="my-2">sur</p>

                                            <v-row class="ma-0">
                                                <v-text-field v-model="local_alert.configuration[index].variation_time"
                                                              class="text-field-duo" filled type="number"
                                                              :label="$t('dialog_update_alert_component.value')"
                                                />
                                                <v-spacer/>
                                                <v-select
                                                    v-model="local_alert.configuration[index].time_unites_selected"
                                                    :items="time_unites"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                    class="text-field-duo" filled return-object
                                                    item-text="label" item-value="value"
                                                    :label="$t('dialog_update_alert_component.unity')"
                                                />
                                            </v-row>
                                        </div>
                                    </v-expand-transition>
                                </v-card-text>
                            </v-card>
                        </div>

                        <v-btn color="secondary" outlined class="my-4" @click="addConditionToCurrentAlert()">
                            <v-icon color="secondary">mdi-plus</v-icon>
                            <span class="font-weight-bold secondary--text">
                                {{ $t('dialog_update_alert_component.add_condition') }}
                            </span>
                        </v-btn>

                        <v-expand-transition>
                            <div
                                v-if="local_alert.configuration[0].type !== null && local_alert.configuration[0].id_metric !== null"
                            >
                                <v-divider class="my-4"/>

                                <!--------------------------
                                    ALERT DISPLAY OPTION
                                --------------------------->
                                <div>
                                    <v-card>
                                        <v-card-title class="pointer py-1"
                                                      @click="manageDisplayAlertModule('option_display')"
                                        >
                                            <p class="font-weight-bold mb-2 font-14 text-uppercase">
                                                {{ $t('dialog_update_alert_component.alert_appearance') }}
                                            </p>
                                        </v-card-title>

                                        <v-expand-transition>
                                            <v-card-text v-if="display_alert_module['option_display']">
                                                <v-text-field v-model="local_alert.label"
                                                              class="mb-6" filled hide-details type="text"
                                                              :label="$t('dialog_update_alert_component.name_alert')"
                                                />

                                                <icon-picker-component
                                                    :color_icon="local_alert.configuration_appearance.icon_untriggered"
                                                    :icon="local_alert.configuration_appearance.icon_untriggered"
                                                    :label="$t('dialog_update_alert_component.icon_alert_not_trigger')"
                                                    type="unactive"
                                                    v-on:editIcon="editIcon"
                                                />

                                                <v-sheet :color="local_alert.configuration_appearance.color_untriggered"
                                                         class="mb-4 mt-2 rounded rounded-b-0" elevation="0"
                                                         @click="dialog_color_unactive = true"
                                                >
                                                    <div class="px-2 py-1">
                                                        <p class="color-white-fix mb-0">
                                                            {{
                                                                $t('dialog_update_alert_component.color_alert_not_trigger')
                                                            }}
                                                        </p>
                                                        <p class="color-white-fix mb-2">
                                                            {{ local_alert.configuration_appearance.color_untriggered }}
                                                        </p>
                                                    </div>
                                                </v-sheet>

                                                <v-dialog v-model="dialog_color_unactive" max-width="450px">
                                                    <v-card class="mx-auto" width="300px">
                                                        <v-card-actions>
                                                            <span class="font-weight-bold">
                                                                {{
                                                                    $t('dialog_update_alert_component.selection_color_alert_not_trigger')
                                                                }}
                                                            </span>
                                                        </v-card-actions>

                                                        <v-card-text class="pb-1">
                                                            <v-color-picker class="mx-auto border" mode="hexa"
                                                                            v-model="color_untriggered"
                                                            />
                                                        </v-card-text>
                                                        <v-card-actions class="justify-end">
                                                            <v-btn text @click="color_untriggered = local_alert.configuration_appearance.color_untriggered; dialog_color_unactive = false">
                                                                <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                                                            </v-btn>

                                                            <v-btn
                                                                color="secondary"
                                                                text
                                                                @click="local_alert.configuration_appearance.color_untriggered = color_untriggered; dialog_color_unactive = false"
                                                                class="btn-secondary-loader"
                                                            >
                                                                <span class="font-weight-bold secondary--text">{{ $t('global.validate') }}</span>
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>


                                                <icon-picker-component
                                                    :color_icon="local_alert.configuration_appearance.color_triggered"
                                                    :icon="local_alert.configuration_appearance.icon_triggered"
                                                    :label="$t('dialog_update_alert_component.icon_alert_trigger')"
                                                    type="active" v-on:editIcon="editIcon"
                                                />

                                                <v-sheet
                                                    :color="local_alert.configuration_appearance.color_triggered"
                                                    class="mb-4 mt-2 rounded rounded-b-0"
                                                    elevation="0"
                                                    @click="dialog_color_active = true"
                                                >
                                                    <div class="px-2 py-1">
                                                        <p class="color-white-fix mb-0">
                                                            {{
                                                                $t('dialog_update_alert_component.color_alert_trigger')
                                                            }}
                                                        </p>
                                                        <p class="color-white-fix mb-2">
                                                            {{ local_alert.configuration_appearance.color_triggered }}
                                                        </p>
                                                    </div>
                                                </v-sheet>

                                                <v-dialog v-model="dialog_color_active" max-width="450px">
                                                    <v-card class="mx-auto" width="300px">
                                                        <v-card-actions>
                                                            <span class="font-weight-bold">
                                                                {{
                                                                    $t('dialog_update_alert_component.selection_color_alert_trigger')
                                                                }}
                                                            </span>
                                                        </v-card-actions>

                                                        <v-card-text class="pb-1">
                                                            <v-color-picker class="mx-auto border" mode="hexa"
                                                                            v-model="color_triggered"
                                                            />
                                                        </v-card-text>

                                                        <v-card-actions class="justify-end">
                                                            <v-btn text @click="color_triggered = local_alert.configuration_appearance.color_triggered; dialog_color_active = false">
                                                                <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                                                            </v-btn>

                                                            <v-btn
                                                                color="secondary"
                                                                text
                                                                @click="local_alert.configuration_appearance.color_triggered = color_triggered; dialog_color_active = false"
                                                                class="btn-secondary-loader"
                                                            >
                                                                <span class="font-weight-bold secondary--text">{{ $t('global.validate') }}</span>
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                            </v-card-text>
                                        </v-expand-transition>
                                    </v-card>
                                </div>

                                <v-divider class="my-4"/>

                                <!-------------
                                    CONTACT
                                -------------->
                                <p class="text-decoration-underline text-uppercase font-16 secondary--text font-weight-bold pointer"
                                   @click="is_display_advanced_option = !is_display_advanced_option"
                                >
                                    {{ $t('dialog_update_alert_component.advanced_settings') }}
                                </p>

                                <v-expand-transition>
                                    <div v-if="is_display_advanced_option">
                                        <span>{{
                                                $t('dialog_update_alert_component.default_contact_preferences')
                                            }}</span>
                                        <v-row class="ma-0 mb-2 mt-2 min-height-40">
                                            <div>
                                                <p class="font-12 ml-1 mb-0">
                                                    {{ $t('dialog_update_alert_component.badge') }}
                                                </p>
                                                <v-switch class="mt-0" inset hide-details
                                                          v-model="local_alert.notification_default_badge"
                                                />
                                            </div>

                                            <div>
                                                <p class="font-12 ml-1 mb-0">
                                                    {{ $t('dialog_update_alert_component.notifications') }}
                                                </p>
                                                <v-switch class="mt-0" inset hide-details
                                                          v-model="local_alert.notification_default_push"
                                                />
                                            </div>

                                            <div>
                                                <p class="font-12 ml-2 mb-0">
                                                    {{ $t('dialog_update_alert_component.sms') }}
                                                </p>
                                                <v-switch class="mt-0" inset hide-details
                                                          v-model="local_alert.notification_default_sms"
                                                />
                                            </div>

                                            <div>
                                                <p class="font-12 ml-1 mb-0">
                                                    {{ $t('dialog_update_alert_component.email') }}
                                                </p>
                                                <v-switch class="mt-0" inset hide-details
                                                          v-model="local_alert.notification_default_email"
                                                />
                                            </div>
                                        </v-row>

                                        <v-radio-group v-model="local_alert.trigger_frequency_default" class="mt-0 mb-2"
                                                       hide-details>
                                            <v-radio :value="-1">
                                                <template v-slot:label>
                                                    <span class="font-14">
                                                        {{ $t('dialog_update_alert_component.one_time') }}
                                                    </span>
                                                </template>
                                            </v-radio>

                                            <v-radio :value="24">
                                                <template v-slot:label>
                                                    <span class="font-14">
                                                        {{ $t('dialog_update_alert_component.each_day') }}
                                                    </span>
                                                </template>
                                            </v-radio>
                                            <v-radio :value="0">
                                                <template v-slot:label>
                                                    <span class="font-14">
                                                        {{ $t('dialog_update_alert_component.each_data') }}
                                                    </span>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>

                                        <v-alert type="info" text icon="mdi-information-outline" dismissible
                                                 close-icon="mdi-close">
                                            {{ $t('dialog_update_alert_component.frequency_common') }}
                                        </v-alert>
                                    </div>
                                </v-expand-transition>
                            </div>
                        </v-expand-transition>
                    </v-form>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="closeDialogEditAlert()">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>

                    <v-btn :disabled="!valid" :loading="edit_loading" class="btn-secondary-loader"
                           color="secondary" text @click="editAlert()"
                    >
                        <span :class="valid ? 'secondary--text font-weight-bold' : 'font-color-light font-weight-bold'">
                            {{ $t('global.validate') }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import IconPickerComponent from "@/components/Alert/IconPickerComponent";
import AlertRepository from "../../repositories/AlertRepository";
import DeviceRepository from "@/repositories/DeviceRepository";

export default {
    name: 'DialogUpdateAlertComponent',
    components: {IconPickerComponent},
    props: {
        alert: {
            type: Object,
            required: true
        },
        is_new_alert: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            dialog_edit_alert: false,
            local_alert: {
                configuration: [
                    {
                        id_metric: null,
                        type: null
                    }
                ],
                configuration_appearance: {},
                user_preference: {},
            },
            color_untriggered: '',
            color_triggered: '',

            edit_loading: false,

            metrics: [],
            level_unites: [],
            time_unites: [
                {
                    value: 'minute',
                    label: 'minutes'
                },
                {
                    value: 'hours',
                    label: 'heures'
                }
            ],

            trigger_frequency: [
                {
                    value: -1,
                    label: 'default'
                }
            ],

            dialog_color_active: false,
            dialog_color_unactive: false,
            type_measure: [
                {value: 'limit', text: this.$t('dialog_update_alert_component.limit')},
                {value: 'variation', text: this.$t('dialog_update_alert_component.variation')},
            ],

            display_alert_module: {
                'configuration_0': true,
                'option_display': false,
                'option_contact': false
            },
            is_display_advanced_option: false,
            rebuild: false,
        }
    },
    computed: {
        valid() {
            let valid_configuration = true;
            this.local_alert.configuration.forEach(configuration => {
                if (configuration.type === null || configuration.operator === null || configuration.limit === null || configuration.limit === '' || configuration.unit === null) {
                    valid_configuration = false;
                }

                if (configuration.type === 'variation' && (configuration.variation_time === null || configuration.variation_time === '' || configuration.time_unites_selected === null)) {
                    valid_configuration = false;
                }
            });

            return valid_configuration
                && this.local_alert.configuration_appearance.icon_triggered !== null
                && this.local_alert.configuration_appearance.color_triggered !== null
                && this.local_alert.configuration_appearance.icon_untriggered !== null
                && this.local_alert.configuration_appearance.color_untriggered !== null;
        }
    },
    methods: {
        editIcon(new_icon, type) {
            if (type === 'active') {
                this.local_alert.configuration_appearance.icon_triggered = new_icon;
            } else {
                this.local_alert.configuration_appearance.icon_untriggered = new_icon;
            }
        },

        editAlert() {
            this.edit_loading = true;

            this.local_alert.configuration.forEach((configuration, index) => {
                if (configuration.type === 'variation' && configuration.time_unites_selected.value === "hours") {
                    this.local_alert.configuration[index].variation_time *= 60;
                }
            });

            if (this.is_new_alert) {
                this.local_alert.id_device = this.$parent.tank.devices[0].id_device;
                AlertRepository.addAlert(this.local_alert).then(() => {
                    this.$parent.getAlerts();
                    this.closeDialogEditAlert();
                }).catch((error) => {
                    this.manageError(error);
                }).finally(() => {
                    this.edit_loading = false;
                });
            } else {
                AlertRepository.updateAlert(this.local_alert.id_alert, this.local_alert).then(() => {
                    this.showSnackbar('success', this.$t('dialog_update_alert_component.updated'));
                    this.$parent.getAlerts();
                }).catch((error) => {
                    this.manageError(error);
                }).finally(() => {
                    this.edit_loading = false;
                    this.closeDialogEditAlert();
                });
            }

            this.display_alert_module = {
                'configuration_0': true,
                'option_display': false,
                'option_contact': false
            }
        },

        closeDialogEditAlert() {
            this.dialog_edit_alert = false;
            this.is_display_advanced_option = false;
        },

        getMetrics(id_device) {
            DeviceRepository.getAllMetricByIdDevice(id_device).then((success) => {
                this.metrics = success.data.data;
                this.changeMetric('all');
            }).catch((error) => {
                this.manageError(error);
            });
        },

        changeMetric(index) {
            if (index === 'all') {
                this.local_alert.configuration.forEach((config, local_index) => {
                    if (config.id_metric) {
                        this.level_unites[local_index] = this.metrics.find(metric => metric.id_metric === config.id_metric).unit;
                    }
                });
            } else if (this.local_alert.configuration[index].id_metric) {
                this.level_unites[index] = this.metrics.find(metric => metric.id_metric === this.local_alert.configuration[index].id_metric).unit;
            }
            if (this.is_new_alert) {
                this.local_alert.configuration_appearance.icon_triggered = 'alert-minus-outline'
                this.local_alert.configuration_appearance.icon_untriggered = 'alert-minus-outline'
            }
        },

        manageDisplayAlertModule(module) {
            this.rebuild = true;
            Object.keys(this.display_alert_module).forEach(key => {
                this.display_alert_module[key] = false;
            });

            this.display_alert_module[module] = true;
            this.rebuild = false;
        },

        addConditionToCurrentAlert() {
            this.local_alert.configuration.push({
                id_metric: null,
                type: null,
                operator: null,
                limit: null,
                unit: null,
                variation_time: null,
                time_unites_selected: null
            });
            this.display_alert_module['configuration_' + (this.local_alert.configuration.length - 1)] = false;
            this.manageDisplayAlertModule('configuration_' + (this.local_alert.configuration.length - 1));
        },

        deleteCondition(index) {
            this.local_alert.configuration.splice(index, 1);
        }
    },
    watch: {
        alert: {
            handler() {
                this.local_alert = JSON.parse(JSON.stringify(this.alert));
                this.color_untriggered = this.local_alert.configuration_appearance.color_untriggered;
                this.color_triggered = this.local_alert.configuration_appearance.color_triggered;
                if (this.metrics.length > 0) {
                    this.changeMetric('all');
                }
            },
            deep: true
        },
        'local_alert.configuration': {
            handler() {
                let label = "";

                this.local_alert.configuration.forEach((configuration, index) => {
                    if (configuration.id_metric && configuration.type && configuration.operator && configuration.limit && configuration.unit) {
                        let and = index >= 1 ? 'ET ' : '';

                        let operator = configuration.operator === "+" ? this.$t('dialog_update_alert_component.augmentation_to')
                            : this.$t('dialog_update_alert_component.diminution_to');
                        if (configuration.type === 'limit') {
                            operator = 'égale à';
                            if (configuration.operator !== "=") {
                                operator = configuration.operator === "<" ? this.$t('dialog_update_alert_component.inferior_to')
                                    : this.$t('dialog_update_alert_component.superior_to');
                            }
                        }

                        label +=
                            and + (this.metrics.find(metric => metric.id_metric === configuration.id_metric) ? this.metrics.find(metric => metric.id_metric === configuration.id_metric).label : '') +
                            " " + (operator ? operator : '') + " " +
                            (configuration.limit ? configuration.limit : '') +
                            (configuration.unit ? configuration.unit : '') + " ";

                        if (configuration.type === 'variation') {
                            label +=
                                "en " +
                                (configuration.variation_time ? configuration.variation_time : '') + " " +
                                (configuration.time_unites_selected ? configuration.time_unites_selected.label : '') + " ";
                        }
                    }
                });
                this.local_alert.label = label;
            },
            deep: true
        },
    }
}
</script>

<style>
.text-field-duo {
    max-width: 49% !important;
}
</style>